/* fieldset {
    border-color: unset !important;
    border-width: 0px !important;
} */

h1,
h2,
h3,
h4,
h5,
h6
 {
  margin: 0;
  margin-bottom: unset !important;
}
.muiSelect {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.64px;
    text-align: left;
    min-width: 125px;
    border: 1px solid #F2994A;
    color: #F2994A !important;
    border-radius: 10px !important;
    padding: 0px 15px !important;

}

.muiSelect svg {
    fill: #F2994A !important;
}

.muiSelect.approved {
    background-color: #219653;
    color: #fff !important;
    border: 1px solid #219653 !important;

    
}
.muiSelect.approved svg,.muiSelect.declined svg {
    fill: #fff !important;
}

.muiSelect.declined{
    background-color: #EB5757;
    color: #fff !important;
    border: 1px solid #EB5757 !important;
}
.MuiSelect-select{
    padding: 7px 14px !important;
}
.move_dots{
    border: 1px solid #7A6565;
    padding: 9px 16px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}