@import ../../../../utils/scss/index

#Footer_Main_Container
  .con-footer
    background: $dark-grey-100
    color: $dark-grey-50

    .con-header
      padding: 24px 0

      #logo
        color: $white-100

    .con-context
      &>div
        padding: 20px 0

      .con-title
        padding: 20px 0

        h3
          text-transform: uppercase
          font-size: 14px
          font-weight: 700

      $line-height: 2.4

      .section-b
        .con-body
          li
            line-height: $line-height

      .section-c
        .con-body
          p
            line-height: $line-height

    $space: 40px 0 0 0
    .con-bottom
      border-top: 1px solid #343434
      margin: $space
      padding: $space

@media (min-width: 992px)
  .con-footer

    .con-context
      &>div:nth-child(even)
        display: flex
        justify-content: center
