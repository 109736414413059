 p {
    color:  #000;

    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.experience-block h2 {
    color:  #000;
    margin: unset !important;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tip {
    padding: 1px 10px;
    border-radius: 10px;
    background: var(--Primary-100, #CC5600);

}

.tip h5 {
    color: var(--Light-Grey-10, #FDFDFD);
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.experience-block p {
    color:  #000;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}