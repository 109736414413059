@import ../../../utils/scss/index

.main
  button.button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 14px 30px
    gap: 10px
    background: $primary-color
    border-radius: 10px
    transition: .5s
    border: 1px solid $trns
    @include button()

    & > *
      transition: .5s
      @include button()

    &:hover > *
      color: $dark-grey-95

    &:hover
      @include alternate-btn($dark-grey-95, $bord-col:$dark-grey-95)

    &.smallBtn
      padding: 8px 24px
      border-radius: 5px
      @include button(600, .9rem)

      & > *
        font-weight: 600 !important
        font-size: 1rem !important
        margin-bottom: unset !important

      &:hover
        @include alternate-btn($dark-grey-95, $bord-col:$dark-grey-95)

    &.secondaryBtn
      border: 1px solid $primary-color
      background: $trns

      & > *
        @include button($color:$primary-color)

    &.darkBtn
      background: $dark-grey-95

      &:hover > *
        color: $dark-grey-95

      &:hover
        @include alternate-btn($dark-grey-95, $bord-col:$dark-grey-95)

    &.fadeBtn
      background: #D5D5D6

    &.emptyBtn
      @include alternate-btn($dark-grey-95)

      & > *
        @include alternate-btn($dark-grey-95)

      &:hover
        @include alternate-btn($primary-color)

        & > *
          @include alternate-btn($primary-color)

    &.primaryEmptyBtn
      @include alternate-btn($primary-color)

      & > *
        @include button($color:$primary-color)

      &:hover
        @include alternate-btn($dark-grey-95)

    &.secondaryOutlineBtn
      @include alternate-btn($dark-grey-95, $bord-col: $dark-grey-95)

      & > *
        @include button($color:$dark-grey-95)

      &:hover > *
        color: #fff

      &:hover
        @include alternate-btn(#fff, $dark-grey-95)

    &.primaryOutlineBtn
      @include alternate-btn($primary-color, $bord-col: $primary-color)

      & > *
        @include button($color:$primary-color)

      &:hover > *
        color: #fff

      &:hover
        @include alternate-btn(#fff, $dark-grey-95)

    &.fade-btn-null
      @include alternate-btn($dark-grey-30, $bord-col: $dark-grey-30)

      & > *
        @include button($color:$dark-grey-30)

      &:hover > *
        color: $dark-grey-95

    &.disabled
      opacity: 0.46
    &.greenBtn
      border-color: #0FAA4E !important
      color: #0FAA4E !important
      &:hover
        color: #FFFFFF !important
        background: #0FAA4E
    &.redBtn
      border-color: #E23636 !important
      color: #fff !important
      background:#E23636 !important
      &:hover
        color: #FFFFFF !important
        background: #red
