.page-item button {
    border: none;
}

.page-link {
    border: none !important;
    background-color: none !important;
    color: rgba(23, 45, 45, 0.34) !important;
    text-align: center;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%
}

.activepage button,
.activepage.button {
    background-color: #CC5600 !important;
    color: #ffffff !important;
    outline: none !important;
    box-shadow: none !important;
}