.top-container {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 background: #ffffff;
 padding: 28px 30px;
 border-radius: 10px;
 border-bottom-right-radius: 0;
 border-bottom-left-radius: 0;
}

.top-container .name {
 color: #2e2e30;
 font-family: Plus Jakarta Sans;
 font-size: 36px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
}

.bottom_radius {
 border-bottom-right-radius: 10px !important;
 border-bottom-left-radius: 10px !important;
}

.top_radius {
 border-top-right-radius: 10px !important;
 border-top-left-radius: 10px !important;
}

.container_hold {
 background-color: #f6f6f6 !important;
}

.all-conatiners {
 padding: 60px 0;
}

.options {
 border: 1px solid #7a6565;
 padding: 7px 14px;
 border-radius: 10px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
}

.text_overflow {
 display: -webkit-box;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
}

.greenBtn {
 border-color: #0faa4e !important;
 color: #0faa4e !important;
}

.table-container {
 padding: 28px 30px;
 background-color: #ffffff;
 border-radius: 10px;
 border-top-right-radius: 0;
 border-top-left-radius: 0;
}

.input_text {
 font-family: Plus Jakarta Sans;
 font-size: 18px;
 font-weight: 400;
 line-height: 28px;
 text-align: left;
 color: #666666;
}

.input-search,
.input-filter {
 border-bottom: 1px solid #cfcfcf;
 align-items: center;
}

.input_select {
 width: 100% !important;
 height: 100% !important;
 border: none !important;
}

.input-search input {
 width: 100%;
 height: 100%;
 border: none;
 padding: 0 10px !important;
}

.input-search input:focus {
 padding: unset;
 padding-left: 20px !important;
}

.input-filter select {
 width: 100%;
 background: url("/public/chevrond.svg") !important;
 background-repeat: no-repeat !important;
 background-position: right !important;
 background-size: contain;
}

.header_con h4 {
 color: #000;
 font-family: Plus Jakarta Sans;
 font-size: 18px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
}

.old_table .old_table .table > :not(caption) > * > * {
 padding: 0.5rem 2rem;
 vertical-align: middle;
}

.old_table .table-borderless > :not(caption) > * > * {
 border-bottom-width: 24px !important;
 border-bottom-color: #ffffff !important;
}

.old_table .table thead tr th,
.new_table .table thead tr th {
 background: #edf1f4 !important;
 color: #272e36;

 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 150%;
 border-bottom: 1px solid #d4dae2;
}

.old_table .table tbody tr td,
.new_table .table tbody tr td {
 color: #021722;
 font-family: Plus Jakarta Sans;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 150%;
 background: #edf1f4 !important;
}

.new_table .table tbody tr td,
.new_table .table thead tr th {
 background: unset !important;
 padding: 1rem !important;
}

.old_table .table tbody tr td span {
 background: #ffb700;
 padding: 1px 10px;
 border-radius: 10px;
 display: inline-block;
}

.old_table .table tr > td:first-child {
 border-bottom-left-radius: 33px;
 border-top-left-radius: 33px;
}

.old_table .table tr > td:last-child {
 border-bottom-right-radius: 33px;
 border-top-right-radius: 33px;
}

.user-image {
 width: 49px;
 height: 49px;
 border-radius: 50%;
 border: 1px solid rgb(204, 86, 0);
}

.user-image img {
 width: 100%;
 height: 100%;
 object-fit: cover;
 border-radius: 50%;
}

.pagination_style {
 gap: 35px;
}

.switch_view {
 padding: 10px 12px;
 border-radius: 8px;
 background: #f2f2f2;
 position: relative;
}

.switch_icon img {
 width: 20px;
 height: 20px;
}

.pick_view {
 padding: 0px 12px;

 background: #ddd2d2;
 width: 100%;
 position: absolute;
 bottom: 0;
 margin-bottom: -20px;
 left: 0;
}

.pick_view label {
 margin: unset !important;
}

.tag,
.tag2 {
 background: #ffb700;
 padding: 1px 10px;
 border-radius: 10px;
 display: inline-block;
 text-transform: capitalize;
}

.tag2 {
 background: #e8e8e8;
}

.action_box {
 border-radius: 10px;
 position: absolute;
 top: 100%;
 left: 0px;
 padding: 10px;
 box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 border: 1px solid #cc5600 !important;
 z-index: 1000;
 background-color: #fff;
}

.action_box button {
 background: #cc5600 !important;
 color: #fff !important;
 border: 1px solid #cc5600 !important;
 display: flex;
 align-items: center;
 gap: 2px;
 padding: 8px 8px !important;
}

.action_box .block {
 background: transparent !important;
 color: rgb(238, 84, 84) !important;
 border: 1px solid rgb(238, 84, 84) !important;
}

.select_options {
 box-shadow: 12px 12px 30px 0px #00000040;
 overflow-y: auto;
 max-height: 200px;
 min-width: 200px;
 border-radius: 10px;
 padding: 10px;
 background: #fefefe;

 position: absolute;
 margin-top: 3rem;
 z-index: 1000;
}

.title_text {
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-weight: 600;
 line-height: 22.68px;
 text-align: left;
 text-transform: capitalize;
}

/* Define scrollbar styles */
::-webkit-scrollbar {
 width: 4px;
}

/* Track */
.select_options::-webkit-scrollbar-track {
 background: #cb5600;
 /* color of the track */
}

/* Handle */
.select_options::-webkit-scrollbar-thumb {
 background: #888;
 /* color of the scroll thumb */
}

/* Handle on hover */
.select_options::-webkit-scrollbar-thumb:hover {
 background: #555;
 /* color of the scroll thumb on hover */
}

@media (min-width: 992px) {
 .switch_col.col-lg-1 {
  flex: 0 0 auto;
  width: 10.33333333%;
 }
}

.warning-img {
 width: 112.146px;
 height: 97.904px;
}

.modal-backdrop {
 background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-header {
 background: #000000;
}

@media (min-width: 1281px) {
 .second-colum {
  flex: 0 0 auto;
  width: 81% !important;
 }
}
