.update_title {
    font-family: Plus Jakarta Sans;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;

}

.section_con {
    border-top: 1px solid #D4DAE2;
    margin: 10px 0;
}

.section_con_header {
    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.section_con ._helper_text {

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #666666;

}

._notes_header {
    display: flex;
    justify-content: space-between;
}

._notes_header h2 {
    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.24px;
    text-align: left;
}

._notes_body p.text {

    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #222222;

}

.text_link {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #404040;
}

.learn_more {
    color: #128BA6 !important;
    text-decoration: underline !important;
}

.note_list {
    padding-left: 1rem !important;
    list-style-type: disc !important;
}

.note_list li {
    list-style-type: disc !important;

}

.note_list .list_title,
.note_list list_text {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #404040;
}
.note_list list_text{
    font-weight: 400;

}