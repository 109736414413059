.container-01 {
    padding: 40px 0;
}

.job-container {
    height: 100%;

}

.job-container .outlinewrap {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px 0px;
    border-radius: 10px;
    height: 100%;
    background-color: #ffffff;
    padding: 30px 20px;

}

.job-logo,
.job-description,
.expected-pay {
    padding: 10px 0;
    border-bottom: 1px solid #000000;
}

.section-name {
    color: #2E2E30;
    /* Form Header - 18 */
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.job-logo .logo-image {
    width: 92px;
    height: 92px;
    padding: 20px;
    background-color: #FFB700;
}

.job-title h4 {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.job-title h3 {
    color: var(--greyscale-k, #000);
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

}

.job-title p {
    color: var(--Dark--Gray-100, #2E2E30);
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.job-title .company-link {
    color: var(--greyscale-k, #000);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    text-decoration-line: underline;
}

.expected_pay_currency {
    color: #CC5600;
    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.skill_list {
    color:  #000;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    list-style: square;
    
}