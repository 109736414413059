$white-100: #FFFFFF

$dark-grey-105: #030303
$dark-grey-100: #1F1F1F
$dark-grey-98: #1A1A1A
$dark-grey-97: #232323
$dark-grey-95: #2E2E30
$dark-grey-90: #3B3D3F
$dark-grey-80: #585859
$dark-grey-70: #6D6D6F
$dark-grey-50: #808080
$dark-grey-40: #ABABAC
$dark-grey-35: #C8C8C8
$dark-grey-30: #C1C1C1
$dark-grey-20: #D5D5D6
$dark-grey-10: #EBEBEB

$light-grey-20: #FAFAFA
$light-grey-40: #F6F6F6

$primary-color: #CC5600
$primary-10: #FAEFE6

$secondary-100: #FFB700
$secondary-30: #FFEAB3
$secondary-20: #FFF1CC
$secondary-10: #FFF8E6
$secondary-5: #FFFBF2

$button-font-color: #FDFDFD
$trns: transparent

$success-100: #0FAA4E

$error-100: #E23636

$form-shadow:  0px 10px 30px 0px #00000026

