.admin_name {
 font-family: Plus Jakarta Sans;
 font-size: 24px;
 font-weight: 700;
 line-height: 30.24px;
}

.role_tag {
 padding: 1px 6px;
 background: #219653;
 border-radius: 10px;
 text-transform: capitalize;
 color: #fff;
}

.admin_contact .admin_email {
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-weight: 700;
 line-height: 20.16px;
 text-align: left;
 display: flex;
 gap: 5px;
}

.admin_contact .admin_number {
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-weight: 400;
 line-height: 22.4px;
 text-align: left;
 display: flex;
 gap: 5px;
}

.admin_activity {
 border-top: 1px solid #ababac;
}

.admin_activity h2 {
 font-family: Plus Jakarta Sans;
 font-size: 24px;
 font-weight: 700;
 line-height: 30.24px;
 text-align: left;
}

.activity_table td,
.activity_table th {
 background: transparent !important;
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-weight: 400;
 line-height: 30px;
}

.activity_table th {
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-weight: 700;
 line-height: 20.16px;
}
.user-img{
  padding: 5px;
}
.user-img img{
  object-fit: cover;
  height: 54px;
  width: 54px;
}
