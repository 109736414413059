.modal_body {
    padding: 10px;
}

.modal_header {
    background: #000000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_header h2 {

    font-size: 16px;
    font-weight: 400;
    line-height: 10.66px;


}

.tag {
    color: #CC5600;
    background: #FAEFE6;
    padding: 5px 10px;
    border-radius: 50px;

}

.modal_title {


    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;

}

.alert_text {
    font-size: 36px;
    font-weight: 700;
    line-height: 45.36px;


}

.alert_question {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;

}

.modal_body label{
color:  #000000 !important;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-weight: 600;
line-height: 20.16px;
text-align: left;
margin: 5px 0;

}