.container-01
  max-width: 1200px
  width: 90%
  margin: 0 auto

  #logo
    font-size: 24px
    font-weight: 700
    text-transform: uppercase

.con-margin-height
  margin-top: 10vh

#con-navbar-sidebar
  padding: 40px 0px
  width: 100%

  li
    border-bottom: 1px solid #fff
    padding: 26px

  & a:last-child li
    border: 0

  & a.active li
    color: $primary-color
    background: #1e1e1e

.arrow-btn
  padding: 14px 20px
  background: $primary-color
  color: #fff
  border-radius: .4rem
  width: fit-content
  cursor: pointer
  border: 2px solid

  &.outline
    color: $primary-color
    background: $trns
    border: 2px solid

.outline-wrap
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px 0px
  border-radius: 10px
  // border: 4px solid #2E2E30

.radio-button
  cursor: pointer
  $size: 16px
  padding: 4px
  width: $size
  height: $size
  border: 2px solid $dark-grey-95
  border-radius: 50%
  @include flex()

  .dot
    padding: 4px
    background: #fff
    border-radius: 50%

    &.active
      background: $primary-color

.con-upload
  .upload
    border: 1px solid $primary-color
    background: transparent
    color: $primary-color
    border-radius: .4rem
    padding: 12px 24px
    cursor: pointer
    font-size: 1rem

    input
      display: none
