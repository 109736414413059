.error-msg
  font-weight: 400
  font-size: 1rem
  color: #ff0000

.control-form
  margin-bottom: 30px
  width: 100%



  label
    font-weight: 600
    font-size: 1.1rem
    margin-bottom: 20px

  input, select,textarea
    padding: 20px 14px
    border: 2px solid $dark-grey-90
    border-radius: 4px
    width: 100%

    &.error
      border: 2px solid red

  &.con-password
    .password
      border: 2px solid $dark-grey-90
      display: flex
      align-items: center
      border-radius: 4px

      &.error
        border: 2px solid red

      input
        border: none

    i
      padding: 6px 12px 6px 6px

input
  outline: none
  transition: .4s
 

  &:disabled
    color: $dark-grey-40 !important
    border-color: $dark-grey-40 !important

select
  --webkit-appearance: none
  --moz-appearance: none
  appearance: none
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 20px)
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 96% !important
