@import ../../../../utils/scss/index

.card-job
  transition: .25s
  border: 3px solid #2E2E30
  min-width: 250px
  width: 340px
  max-width:400px
  max-height: 140px

  &:hover
    box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9)

  .header
    background: $dark-grey-95
    color: #fff
    padding: 8px 10px

    &.has-box
      padding: 2px 8px 4px 2px

    .box
      padding: 3px
      border-radius: 50%
      border: 1px solid #fff

      &.box-1, &.box-2, &.box-3
        padding: 8px
        border-radius: 0
        border: 0

      &.box-1
        background: $primary-color

      &.box-2
        background: #FFB700

      &.box-3
        background: #FEFEFE

    i:not(.box)
      padding: 0 8px
      cursor: pointer

  .context
    padding: 3%
    gap: 2%

    @media (min-width: 992px)
      // padding: 10px 14px

    .avatar-img
      $size: 30px
      width: $size
      height: $size

    .con-class
      width: 100%

      .avatar-container
        border: 2px solid red
        .avatar-img
          width: 75px
          height: 75px
          object-fit: cover

      .class-1
        h2
          font-size: 1rem
          font-weight: 600
          white-space: nowrap
          color: $primary-color

          @media (min-width: 992px)
            font-size: 1.2rem

          i
            font-style: normal
            background: $primary-color
            font-size: .8rem
            color: #fff
            border-radius: 1rem
            padding: 2px 8px

        h3, h4
          font-weight: 600
          font-size: 1rem

        h4, p
          font-weight: 400

        p
          font-size: .9rem

      .class-2
        & > i
          cursor: pointer
          padding: 0 8px
          font-size: 1.6rem

        .arrow-btn
          padding: 10px
