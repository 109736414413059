.projectCard {
 display: inline-block;
 border: 3px solid #333333;
 width: 280px;
}

.projectCard .header {
 background: #333333;
 color: #fff;
 padding: 1px 4px;
 white-space: nowrap;
 padding-bottom: 4px;
}

.projectCard .header h2 {
 font-size: 1rem;
}

.projectCard .header i {
 cursor: pointer;
}

.projectCard .body {
 position: relative;
 z-index: 10;
}

.projectCard .body .img {
 height: 140px;
}

.projectCard .body .arrow-btn {
 position: absolute;
 border-radius: 0.4rem;
 right: 10px;
 bottom: 10px;
 padding: 7px 13px;
 border: 1px solid #007bff;
}
