.title-review-con {
    display: flex;
    background: #ffffff;
    padding: 28px 30px;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    flex-direction: column;
    /* align-items: flex-start;
    gap: 23px;
    align-self: stretch */
}

.title-review-con .username {
    color: #2E2E30;
    font-family: Plus Jakarta Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.brief-info {
    color: #2E2E30;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.title-review-con .box {
    display: flex;
    padding: 24px 47px;
    flex-direction: column;
    align-items: center;
    width: 138px;
    height: 115px;
    border-radius: 10px;
    border: 1px solid #000;
}

.box .box-number {
    font-family: Plus Jakarta Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.box span {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}