.container_hold{
    background-color: #FFFBF2 ;

}
.overall-container h1 {
    text-align: center;
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.form-contanier {
    padding: 28px 60px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.text-container {
    text-align: center;
}

.text-container h3 {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.text-container p {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.input-container {
    padding: 11px 13px;
    border-radius: 4px;
    border: 2px solid #3B3D3F;
    height: 60px;
}

.input-container input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent !important;

}

.input-container input:focus {
    padding: unset !important;


}

label,
.error {
    margin: 20px 0;
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.error {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
    color:red;
}
.input-container .eye{
    width: 25px;
    height: 25px;
    cursor: pointer;
}