@mixin flex()
  display: flex
  align-items: center
  justify-content: center

// For Buttons

@mixin button($weight: 700, $size:1.1rem, $color:$button-font-color)
  font-style: normal
  font-weight: $weight
  font-size: $size
  color: $color

@mixin alternate-btn($font-col, $bg:$trns, $bord-col:$trns)
  border: 1px solid $bord-col
  background: $bg
  color: $font-col

// For Tabs

@mixin tab($bg:$trns, $brd-cl:$dark-grey-95, $fnt-cl:$dark-grey-95)
  background: $bg
  border: 1px solid $brd-cl
  & > *
    color: $fnt-cl

// For Badges

@mixin badge($bg, $col:$button-font-color)
  background: $bg
  color: $col

@mixin outline-badge($color)
  @include badge($trns, $color)
  border-color: inherit

@mixin font-block($wgt:600, $size: 1.2rem, $col: #000, $pd-bm: 0)
  font-weight: $wgt
  font-size: $size
  padding-bottom: $pd-bm
  color: $col

@mixin box-shadow-1()
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.9)

@mixin box-shadow-2()
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.9)

@mixin box-shadow-3()
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.9)
