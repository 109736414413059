.sidenav-title {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 border-radius: 10px;
 gap: 8px;
 padding: 12px 15px;
 cursor: pointer;
 width: auto;
 transition: all 0.5s;
}

.sidenav-title:hover {
 background: #cc5600;
 color: white;
}

.activeitem.sidenav-title {
 background: #cc5600;
 color: white !important;
}

.sidenav-title:hover svg {
 stroke: #ffffff !important;
}

.sidenav-title:hover .side-text {
 color: #ffffff !important;
}

.stylewidth:hover .side-text {
 display: inline-block; /* Show the text on hover */
}

.side-text {
 color: #434345;
 font-family: Plus Jakarta Sans;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin: unset !important;
}

.contanier-sidebar {
 background-color: #ffffff;
 height: 100%;
 padding: 28px 8px;
 border-radius: 10px;
}

.contanier-sidebar-items {
 display: flex;
 flex-direction: column;
 gap: 30px;
 justify-content: center;
}

.contanier-sidebar-items:hover .sidenav-title {
 justify-content: start;
}

.notactive .sidenav-icon svg {
 stroke: #434345;
}

.activeitem .side-text {
 color: #ffffff !important;
}

.activeitem .sidenav-icon svg {
 stroke: #ffffff !important;
}

@media (min-width: 993px) {
 .stylewidth:hover {
  width: fit-content !important;
 }
 .side-text {
  display: none;
 }
}

/* @media (min-width:1200px) {
    .contanier-sidebar {
        background-color: #ffffff;
        height: 100%;
        padding: 28px 9px;
        border-radius: 10px;
    }
  

} */
