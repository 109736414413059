.feedback-header {
    border-top: 1px solid #D7D7D7
}

.switch_icon {
    cursor: pointer;
}

.user_feedback {
    padding: 32px 16px 32px 18px;
    border: 1px solid #C0C7CF;
    width: 100%;
    min-height: 413px;
    position: relative;


}

.user_img {
    width: 30px;
    /* height: 30px; */
    border: 1px solid #C0C7CF;
    border-radius: 50%;
    overflow: hidden;
}

.user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user_details {
    display: flex;
    align-items: center;
    gap: 2rem;

}

.user_details .user_email {
    margin-bottom: unset !important;
    font-size: 24px;
    font-weight: 400;

}

.user_details .user_name {
    color: #272E36;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: unset !important;
}


.feedback_date {
    margin-top: 30px;
}

.feedback_date span {
    font-size: 16px;
    font-weight: 400;
    color: #121212;
}

.feedback_subject {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #121212;
}

.feedback_report {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3B4651;
    border: 1px solid #C0C7CF;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 16px;
}


/* Rating */
.feedback_rating img {
    width: 18px;
    height: 18px;
}



/* Costume  Table */
.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.costume_table_header h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.16px;
    color: #272E36;
}

.custom_table_row {
    display: flex;
}

.custom_table_row .user_name {
    /* width: 200px; */
    /* Specify the width of the container */

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: unset !important;

}

.feedbacktext {
    -webkit-line-clamp: 2;
    /* width: 200px; */
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: unset !important;


}

.feedbackstatus select {
    background: #E8E8E8 !important;
    padding: 4px 16px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #434345;
    margin-bottom: unset !important;
}

.custom_table_row img.chevron {
    width: 16px;
    height: 16px;
}

.hide_details {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    color: #CC5600;
    margin-bottom: unset !important;
    cursor: pointer;
}

.hidden_details {
    background-color: #F2F2F2;
}

.hidden_details .user_feedback {
    border: none !important;
    margin-bottom: unset !important;
}

.hidden_details .form_con {
    padding: 0 16px 32px 18px;
}

.hidden_details .form_con h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: unset !important;
}

.hidden_details .form_con select {
    padding-left: unset !important;
}

.hidden_details .input_info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #9B9A95;
    margin-top: 5px;

}

.hidden_details .actions {
    display: flex;
}

.hidden_details .actions img {
    width: 24px;
    height: 24px;
}

.hidden_details .form-floating textarea {
    height: 155px;
    outline: none !important;
}

.hidden_details .form-floating textarea:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: unset !important;
}

.hidden_details .form-floating label {
    margin-top: unset !important;
    padding: 1rem 1.5rem !important;

    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #666666;


}

.closetoggle {
    width: fit-content;
}
/* ------------------------------------------- */
.custom-cell p{
    -webkit-line-clamp: 2;
    max-width:250px;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #3B4651;
    border-bottom: 0 !important;
}

